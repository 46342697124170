import {Html} from '@react-three/drei'
import {cloneElement, createElement, useEffect, useState} from 'react'
import agenciesData from './models/agenciesData'

export default function OverlayMobile() {
    const [agenciesVisible, setAgenciesVisible] = useState(false)
    const [agenciesVisibleTitle, setAgenciesVisibleTitle] = useState(false)
    const [agenciesVisibleExpertise, setAgenciesVisibleExpertise] = useState(false)
    const [hoveredAgency, setHoveredAgency] = useState(null)
    const [video, setVideo] = useState(null)

    // Gestionnaire d'événements pour basculer la visibilité des agences
    const toggleAgenciesVisibility = () => {
        setAgenciesVisible(!agenciesVisible)
        setAgenciesVisibleTitle(!agenciesVisibleTitle)
    }

    const handleTitleHover = (agencyName) => {
        setAgenciesVisibleExpertise(true)
        setHoveredAgency(agencyName)
    }

    const handleTitleUnhover = () => {
        setAgenciesVisibleExpertise(false)
        setHoveredAgency(null)
    }
    const audioToggle = () => {

        const circleLines = document.querySelectorAll('.OverlayMobile-mute-audioLines')
        for (let i = 0; i < circleLines.length; i++) {

            circleLines[i].classList.toggle('volumeChangesMobile')
        }
        video.muted = !video.muted
    }
    useEffect(() => {
        setVideo(document.getElementById('videoMobile'))
    }, [video])
    return (<div className='OverlayMobile'>
        <div className='OverlayMobile-logos'>
            <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
                <defs>
                    <clipPath id='clip-path'>
                        <rect id='Rectangle_24' data-name='Rectangle 24' width='20.149' height='20.149' fill='#fff'/>
                    </clipPath>
                </defs>
                <g id='Groupe_30' data-name='Groupe 30' transform='translate(3.792 3.792)'>
                    <path id='Tracé_32' data-name='Tracé 32'
                          d='M13.462,16.725l2.456,5.014,2.212-3.809,2.214,3.811,2.45-5.021L18.13,8.058Zm6.79,2.465L18.13,15.538l-2.122,3.654-1.2-2.441,3.318-6.159,3.315,6.155Z'
                          transform='translate(-8.053 -4.821)' fill='#fff'/>
                    <g id='Groupe_29' data-name='Groupe 29' transform='translate(0 0)'>
                        <g id='Groupe_28' data-name='Groupe 28'>
                            <path id='Tracé_33' data-name='Tracé 33'
                                  d='M10.075,0A10.075,10.075,0,1,0,20.149,10.075,10.086,10.086,0,0,0,10.075,0m0,18.948a8.874,8.874,0,1,1,8.874-8.874,8.884,8.884,0,0,1-8.874,8.874'
                                  transform='translate(0 0)' fill='#fff'/>
                        </g>
                    </g>
                </g>
                <rect id='Rectangle_25' data-name='Rectangle 25' width='28' height='28' fill='none'/>
            </svg>


            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28">
                <g id="Group_32" data-name="Group 32" transform="translate(-0.291)">
                    <rect id="Rectangle_26" data-name="Rectangle 26" width="27" height="28" transform="translate(0.291)" fill="none"/>
                    <g id="Group_48" data-name="Group 48" transform="translate(-150.989 -113.175)">
                        <path id="Path_48" data-name="Path 48" d="M4.136,4.69,2.869,8.181,4.906,13.1H.833L0,15.356H5.842L6.98,18.108H10.1Z" transform="translate(158.738 119.019)" fill="#fff"/>
                        <path id="Path_49" data-name="Path 49" d="M2.655,7.3l4.718-.016L10.61,0H5.687Z" transform="translate(160.212 116.416)" fill="#fff"/>
                    </g>
                </g>
            </svg>

            <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
                <g id='Groupe_34' data-name='Groupe 34' transform='translate(0.417)'>
                    <g id='Groupe_33' data-name='Groupe 33'>
                        <rect id='Rectangle_26' data-name='Rectangle 26' width='28' height='28'
                              transform='translate(-0.417)'
                              fill='none'/>
                    </g>
                    <path id='Tracé_34' data-name='Tracé 34'
                          d='M2.43,0,0,2.34,7.548,9.267v4.406H10.84V9.261l7.374-6.916L15.944.058,9.2,6.358Z'
                          transform='translate(4.875 7.042)' fill='#fff'/>
                </g>
            </svg>
        </div>
        <div className='OverlayMobile-bottom'>
            {!agenciesVisible && (
                <button className={`OverlayMobile-btn btnPrimary ${!agenciesVisible ? 'agencyBtnAppear' : ''}`}
                        onClick={toggleAgenciesVisibility}>
                    Contactez-nous
                </button>)}
        </div>
        {agenciesVisible && (
            <>
                <div className='OverlayMobile-agencies'>
                    {agenciesData.map((agency) => (<div className='OverlayMobile-agencies-item' key={agency.name}>
                            <div className={'OverlayMobile-agencies-item--title-container'}>
                                {agenciesVisibleTitle && (<a href={agency.link} target={'_blank'}
                                                             className={`OverlayMobile-agencies-item--title ${agenciesVisible ? 'agencyTitleAppear' : ''} ${hoveredAgency === agency.name ? 'hovered' : ''}`}>
                                    {cloneElement(createElement('div'), {
                                        dangerouslySetInnerHTML: {__html: agency.logoMobile},
                                        onMouseEnter: () => handleTitleHover(agency.name),
                                        onMouseLeave: handleTitleUnhover
                                    })}
                                </a>)}
                                <svg className={'OverlayMobile-agencies-item--title-arrow'}
                                     xmlns="http://www.w3.org/2000/svg"
                                     width="12.965" height="13.293"
                                     viewBox="0 0 12.965 13.293">
                                    <g id="Groupe_48" data-name="Groupe 48" transform="translate(-300.302 -124.635)">
                                        <path id="right-up"
                                              d="M16.617,9.584,8.346,18.077,6.9,16.592,15.168,8.1H7.4V6H18.665V17.548H16.617Z"
                                              transform="translate(294.102 119.135)" fill="#1cf5a6"/>
                                        <path id="right-up_-_Outline" data-name="right-up - Outline"
                                              d="M6.9,5.5H19.165V18.048H16.117V10.814L8.346,18.793,6.2,16.593,13.983,8.6H6.9Zm11.264,1H7.9V7.6h8.452L7.6,16.592l.749.768,8.771-9.007v8.694h1.048Z"
                                              transform="translate(294.102 119.135)" fill="#1cf5a6"/>
                                    </g>
                                </svg>
                            </div>
                            <div className={`OverlayMobile-agencies-item--expert-container`}>
                                <p
                                    className={`OverlayMobile-agencies-item--expert expertisesAppear`}>{agency.expertises[0]}</p>
                                <p
                                    className={`OverlayMobile-agencies-item--expert expertisesAppear`}>{agency.expertises[1]}</p>
                                <p
                                    className={`OverlayMobile-agencies-item--expert expertisesAppear`}>{agency.expertises[2]}</p>
                                <p
                                    className={`OverlayMobile-agencies-item--expert expertisesAppear`}>{agency.expertises[3]}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={`OverlayMobile-agencies-cross`} onClick={toggleAgenciesVisibility}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="47.592"
                         height="47.592" viewBox="0 0 47.592 47.592">
                        <defs>
                            <filter id="Line_1" x="0" y="0" width="47.592" height="47.592" filterUnits="userSpaceOnUse">
                                <feOffset input="SourceAlpha"/>
                                <feGaussianBlur stdDeviation="4" result="blur"/>
                                <feFlood flood-color="#fff"/>
                                <feComposite operator="in" in2="blur"/>
                                <feComposite in="SourceGraphic"/>
                            </filter>
                            <filter id="Line_2" x="0" y="0" width="47.592" height="47.592" filterUnits="userSpaceOnUse">
                                <feOffset input="SourceAlpha"/>
                                <feGaussianBlur stdDeviation="4" result="blur-2"/>
                                <feFlood flood-color="#fff"/>
                                <feComposite operator="in" in2="blur-2"/>
                                <feComposite in="SourceGraphic"/>
                            </filter>
                        </defs>
                        <g id="Group_47" data-name="Group 47" transform="translate(-331.076 -54.075)">
                            <g transform="matrix(1, 0, 0, 1, 331.08, 54.08)" filter="url(#Line_1)">
                                <line id="Line_1-2" data-name="Line 1" y2="32.364"
                                      transform="translate(35.24 12.35) rotate(45)" fill="none" stroke="#fff"
                                      stroke-width="1"/>
                            </g>
                            <g transform="matrix(1, 0, 0, 1, 331.08, 54.08)" filter="url(#Line_2)">
                                <line id="Line_2-2" data-name="Line 2" y2="32.364"
                                      transform="translate(35.24 35.24) rotate(135)" fill="none" stroke="#fff"
                                      stroke-width="1"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </>
        )}
    </div>)
}
