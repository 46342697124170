import {Color, LinearFilter, RGBAFormat} from 'three'
import * as THREE from 'three'
import {useEffect, useState} from 'react'

export default function Hangar({onHangarLoaded, model}) {

    const nodes = model.scene ? model.scene.children : []
    const materials = nodes.map(node => node.material)
    const video = document.getElementById('video')

    const [isLoaded, setIsLoaded] = useState(false)

    if (!video) {
        console.error('Video element not found!')
        return null
    }
    const videoTexture = new THREE.VideoTexture(video)
    videoTexture.minFilter = LinearFilter
    videoTexture.magFilter = LinearFilter
    videoTexture.format = RGBAFormat
    videoTexture.crossOrigin = 'anonymous'
    videoTexture.flipY = false
    videoTexture.needsPMREMUpdate = true

    let videoMaterial = new THREE.MeshStandardMaterial()
    const screenMeshes = nodes.filter(node => node.name === "Plane" || node.name === "Plane001" || node.name === "Plane002");
    const cable = nodes.filter(node => node.name === "BezierCurve");
    const backFace = nodes.filter(node => node.name === "Plane003");
    if (screenMeshes.every((mesh) => mesh && mesh.material)) {
        screenMeshes.forEach((mesh) => {
                videoMaterial = mesh.material.clone()
                videoMaterial.map = videoTexture;
                videoMaterial.side = THREE.FrontSide;
                videoMaterial.toneMapped = false;
                videoMaterial.emissiveMap = videoTexture;
                videoMaterial.emissive = new THREE.Color(0xffffff);
                videoMaterial.reflectivity = 0;
                videoMaterial.transparent = false;
                videoMaterial.opacity = 1;
                console.log(videoMaterial)

                const blackMaterial = new THREE.MeshStandardMaterial({
                        emissive: new THREE.Color(0x212121),
                        // emissiveIntensity: 1,
                    }
                )
                blackMaterial.color = new Color(0x212121)
                mesh.material = blackMaterial
            }
        )
    }
    useEffect(() => {
        if (nodes && materials) {

            setIsLoaded(true)
        }
    }, [nodes, materials])

    useEffect(() => {
        if (isLoaded) {
            onHangarLoaded(true)

            setTimeout(() => {
                if (video && screenMeshes.every((mesh) => mesh && mesh.material)) {
                    screenMeshes.forEach((mesh) => {
                        console.log(mesh)
                        mesh.material = videoMaterial
                    })
                }

                video.play()
            }, 2000)
        }
    }, [isLoaded, onHangarLoaded, video, videoMaterial, screenMeshes])

    return <primitive scale={1} object={model.scene}/>
}
