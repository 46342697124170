// Sample data for agencies
const agenciesData = [
    {
        name: 'altimax',
        link: 'https://www.altimax.com/',
        expertises: [ 'Ux/Ui design', 'Développement web','Conseil en stratégie digitale', 'Activation SEO/SEA'],
        logoMobile: `<svg xmlns="http://www.w3.org/2000/svg" width="259" height="59" viewBox="0 0 259 59">
  <g id="Groupe_22" data-name="Groupe 22" transform="translate(-3553.458 0.198)">
    <rect id="Rectangle_21" data-name="Rectangle 21" width="259" height="59" transform="translate(3553.458 -0.198)" fill="none"/>
    <g id="Calque_1" data-name="Calque 1" transform="translate(3595.05 11.368)">
      <g id="Groupe_38" data-name="Groupe 38" transform="translate(0 0)">
        <g id="Groupe_36" data-name="Groupe 36" transform="translate(47.807 4.074)">
          <path id="Tracé_35" data-name="Tracé 35" d="M52.957,10.949a8.079,8.079,0,0,1,3,.545,7.41,7.41,0,0,1,2.361,1.463,6.55,6.55,0,0,1,1.527,2.148,6.438,6.438,0,0,1,.545,2.639v13.12H58l-.876-2.179a7.646,7.646,0,0,1-3.332,2.051,8.674,8.674,0,0,1-2.586.342,7.46,7.46,0,0,1-2.692-.459,6.131,6.131,0,0,1-2.052-1.272,5.915,5.915,0,0,1-1.335-1.88,5.512,5.512,0,0,1-.481-2.286,5.739,5.739,0,0,1,.566-2.565A4.979,4.979,0,0,1,47.176,20.6a11.587,11.587,0,0,1,3.836-1.314,33.8,33.8,0,0,1,6.111-.46v-1.09a3.5,3.5,0,0,0-1.164-2.607,4.175,4.175,0,0,0-3-1.11,4.074,4.074,0,0,0-2.713.758,3.991,3.991,0,0,0-1.218,2.084h-3.5a6.849,6.849,0,0,1,.747-2.3,6.587,6.587,0,0,1,1.517-1.891A6.952,6.952,0,0,1,50.009,11.4a8.691,8.691,0,0,1,2.948-.459ZM51.2,28.02a6.72,6.72,0,0,0,2.4-.417,6.341,6.341,0,0,0,1.87-1.069,5.041,5.041,0,0,0,1.207-1.463,3.443,3.443,0,0,0,.438-1.646V21.888a36.911,36.911,0,0,0-4.765.245,10.189,10.189,0,0,0-2.788.7,2.635,2.635,0,0,0-1.314,1.091,3.181,3.181,0,0,0-.32,1.463,2.421,2.421,0,0,0,.887,1.816,3.387,3.387,0,0,0,2.383.812Z" transform="translate(-44.645 -3.299)" fill="#fff"/>
          <path id="Tracé_36" data-name="Tracé 36" d="M68.971,25.022a3.544,3.544,0,0,0,.9,2.586,3.572,3.572,0,0,0,3.163.833,1.208,1.208,0,0,0,.32-.128v2.842a2.274,2.274,0,0,1-.492.213,4.3,4.3,0,0,1-1.475.213,5.1,5.1,0,0,1-2.168-.48,5.785,5.785,0,0,1-1.827-1.335,6.319,6.319,0,0,1-1.25-2.052,7.465,7.465,0,0,1-.459-2.693V3.805h3.291V25.021Z" transform="translate(-43.155 -3.805)" fill="#fff"/>
          <path id="Tracé_37" data-name="Tracé 37" d="M79.014,6.26h2.842V11.5h4.38v3.076h-4.38V24.85a3.544,3.544,0,0,0,.9,2.586,3.193,3.193,0,0,0,2.393.918,3.46,3.46,0,0,0,.79-.085.911.911,0,0,0,.3-.128v2.842a2.387,2.387,0,0,1-.47.213,4.38,4.38,0,0,1-1.495.213,5.03,5.03,0,0,1-2.159-.48A5.84,5.84,0,0,1,80.3,29.593a6.3,6.3,0,0,1-1.25-2.052,7.465,7.465,0,0,1-.459-2.693V14.571h-3.5V11.494h1.539A2.1,2.1,0,0,0,79.015,9.1V6.26Z" transform="translate(-42.489 -3.631)" fill="#fff"/>
          <path id="Tracé_38" data-name="Tracé 38" d="M90.611,6.1A2.039,2.039,0,0,1,91.2,4.593a1.986,1.986,0,0,1,1.464-.588A2.013,2.013,0,0,1,94.757,6.1a2.028,2.028,0,0,1-2.095,2.073,1.966,1.966,0,0,1-1.464-.6A2.02,2.02,0,0,1,90.611,6.1Zm3.718,25.256H91.038v-19.7h3.291v19.7Z" transform="translate(-41.389 -3.791)" fill="#fff"/>
          <path id="Tracé_39" data-name="Tracé 39" d="M119.857,10.949a5.952,5.952,0,0,1,2.5.545,6.192,6.192,0,0,1,2.116,1.6,7.726,7.726,0,0,1,1.442,2.565,10.274,10.274,0,0,1,.523,3.387V30.863h-3.29V19.048a5.472,5.472,0,0,0-1.133-3.762,3.616,3.616,0,0,0-2.8-1.26,3.865,3.865,0,0,0-2.917,1.346,5.191,5.191,0,0,0-1.25,3.676V30.863H111.78V19.048a5.441,5.441,0,0,0-1.143-3.762,3.648,3.648,0,0,0-2.81-1.26,3.546,3.546,0,0,0-1.548.363,4.012,4.012,0,0,0-1.336,1.048,5.364,5.364,0,0,0-.919,1.6,5.712,5.712,0,0,0-.342,2.009V30.863h-3.291v-19.7h2.415l.876,2.2a5.744,5.744,0,0,1,1.176-1.176,6.542,6.542,0,0,1,1.5-.866,5.451,5.451,0,0,1,2.136-.374,5.308,5.308,0,0,1,2.521.545A7.046,7.046,0,0,1,112.7,12.7a6.521,6.521,0,0,1,1.26,1.753,8.256,8.256,0,0,1,1.41-1.753,8.541,8.541,0,0,1,1.827-1.207,5.848,5.848,0,0,1,2.66-.545Z" transform="translate(-40.697 -3.299)" fill="#fff"/>
          <path id="Tracé_40" data-name="Tracé 40" d="M138.319,10.949a8.083,8.083,0,0,1,3,.545,7.41,7.41,0,0,1,2.361,1.463,6.569,6.569,0,0,1,1.528,2.148,6.438,6.438,0,0,1,.545,2.639v13.12h-2.393l-.875-2.179a7.658,7.658,0,0,1-3.333,2.051,8.676,8.676,0,0,1-2.585.342,7.47,7.47,0,0,1-2.693-.459,6.1,6.1,0,0,1-2.051-1.272,5.915,5.915,0,0,1-1.335-1.88,5.5,5.5,0,0,1-.481-2.286,5.753,5.753,0,0,1,.565-2.565A4.983,4.983,0,0,1,132.54,20.6a11.587,11.587,0,0,1,3.836-1.314,33.712,33.712,0,0,1,6.111-.46v-1.09a3.5,3.5,0,0,0-1.165-2.607,4.175,4.175,0,0,0-3-1.11,4.074,4.074,0,0,0-2.713.758,3.977,3.977,0,0,0-1.218,2.084h-3.5a6.825,6.825,0,0,1,.747-2.3,6.566,6.566,0,0,1,1.516-1.891,6.964,6.964,0,0,1,2.222-1.272,8.728,8.728,0,0,1,2.949-.459ZM136.567,28.02a6.714,6.714,0,0,0,2.4-.417,6.314,6.314,0,0,0,1.87-1.069,5.079,5.079,0,0,0,1.208-1.463,3.443,3.443,0,0,0,.438-1.646V21.888a36.887,36.887,0,0,0-4.765.245,10.19,10.19,0,0,0-2.788.7,2.638,2.638,0,0,0-1.315,1.091,3.182,3.182,0,0,0-.321,1.463,2.421,2.421,0,0,0,.887,1.816,3.392,3.392,0,0,0,2.384.812Z" transform="translate(-38.599 -3.299)" fill="#fff"/>
          <path id="Tracé_41" data-name="Tracé 41" d="M159.131,20.784l7.009,10.064H162.42l-5.256-7.65-5.257,7.65H148.19L155.2,20.784l-6.581-9.636h3.738l4.808,7.222,4.807-7.222h3.718l-6.559,9.636Z" transform="translate(-37.312 -3.285)" fill="#fff"/>
        </g>
        <g id="Groupe_37" data-name="Groupe 37">
          <path id="Tracé_42" data-name="Tracé 42" d="M21.276,29.78l-3.946-6.8-3.946,6.792-4.379-8.94L17.329,5.384l8.316,15.442-4.37,8.952Zm-9.862-8.9,2.132,4.352,3.784-6.516,3.782,6.513,2.126-4.357L17.329,9.9,11.412,20.883Z" transform="translate(0.638 0.381)" fill="#fff"/>
          <path id="Tracé_43" data-name="Tracé 43" d="M17.963,35.926A17.963,17.963,0,1,1,35.926,17.963,17.983,17.983,0,0,1,17.963,35.926Zm0-33.785A15.821,15.821,0,1,0,33.785,17.963,15.839,15.839,0,0,0,17.963,2.142Z" fill="#fff"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`,
        logo: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'171\'\n' +
            '                     height=\'39\' viewBox=\'0 0 171 39\'>\n' +
            '                  <g id=\'Group_39\' data-name=\'Group 39\' transform=\'translate(-2021)\'>\n' +
            '                    <g id=\'Group_22\' data-name=\'Group 22\' transform=\'translate(-334)\'>\n' +
            '                      <rect id=\'Rectangle_21\' data-name=\'Rectangle 21\' width=\'171\' height=\'39\'\n' +
            '                            transform=\'translate(2355)\' fill=\'none\' />\n' +
            '                      <g id=\'Calque_1\' data-name=\'Calque 1\' transform=\'translate(2382.248 7.533)\'>\n' +
            '                        <g id=\'Group_38\' data-name=\'Group 38\' transform=\'translate(0 0)\'>\n' +
            '                          <g id=\'Group_36\' data-name=\'Group 36\' transform=\'translate(31.679 2.7)\'>\n' +
            '                            <path id=\'Path_35\' data-name=\'Path 35\'\n' +
            '                                  d=\'M50.153,10.948a5.354,5.354,0,0,1,1.99.361,4.91,4.91,0,0,1,1.565.969A4.34,4.34,0,0,1,54.719,13.7a4.266,4.266,0,0,1,.361,1.748v8.694H53.494l-.58-1.444a5.067,5.067,0,0,1-2.208,1.359,5.748,5.748,0,0,1-1.714.226,4.943,4.943,0,0,1-1.784-.3,4.063,4.063,0,0,1-1.36-.843,3.92,3.92,0,0,1-.885-1.246,3.653,3.653,0,0,1-.319-1.515,3.8,3.8,0,0,1,.375-1.7,3.3,3.3,0,0,1,1.3-1.331,7.678,7.678,0,0,1,2.542-.871,22.4,22.4,0,0,1,4.05-.305v-.722a2.316,2.316,0,0,0-.771-1.728,2.766,2.766,0,0,0-1.99-.736,2.7,2.7,0,0,0-1.8.5,2.645,2.645,0,0,0-.807,1.381H45.226a4.538,4.538,0,0,1,.5-1.523,4.365,4.365,0,0,1,1.005-1.253,4.607,4.607,0,0,1,1.472-.843,5.759,5.759,0,0,1,1.953-.3ZM48.992,22.26a4.453,4.453,0,0,0,1.593-.276,4.2,4.2,0,0,0,1.239-.708,3.34,3.34,0,0,0,.8-.969,2.282,2.282,0,0,0,.29-1.091V18.2a24.459,24.459,0,0,0-3.158.162,6.752,6.752,0,0,0-1.848.467,1.746,1.746,0,0,0-.871.723,2.108,2.108,0,0,0-.212.969,1.6,1.6,0,0,0,.588,1.2,2.245,2.245,0,0,0,1.579.538Z\'\n' +
            '                                  transform=\'translate(-44.645 -5.878)\'  />\n' +
            '                            <path id=\'Path_36\' data-name=\'Path 36\'\n' +
            '                                  d=\'M67.861,17.865a2.349,2.349,0,0,0,.595,1.714,2.367,2.367,0,0,0,2.1.552.8.8,0,0,0,.212-.085v1.883a1.507,1.507,0,0,1-.326.141,2.852,2.852,0,0,1-.977.141,3.377,3.377,0,0,1-1.437-.318,3.834,3.834,0,0,1-1.211-.885,4.187,4.187,0,0,1-.828-1.36,4.947,4.947,0,0,1-.3-1.785V3.805h2.181V17.864Z\'\n' +
            '                                  transform=\'translate(-50.754 -3.805)\'  />\n' +
            '                            <path id=\'Path_37\' data-name=\'Path 37\'\n' +
            '                                  d=\'M77.688,6.26h1.883V9.729h2.9v2.039h-2.9v6.811a2.349,2.349,0,0,0,.595,1.714,2.116,2.116,0,0,0,1.586.608,2.292,2.292,0,0,0,.524-.056.6.6,0,0,0,.2-.085v1.883a1.582,1.582,0,0,1-.312.141,2.9,2.9,0,0,1-.991.141,3.333,3.333,0,0,1-1.431-.318,3.87,3.87,0,0,1-1.2-.885,4.175,4.175,0,0,1-.828-1.36,4.947,4.947,0,0,1-.3-1.785V11.767H75.083V9.728H76.1a1.394,1.394,0,0,0,1.586-1.586V6.26Z\'\n' +
            '                                  transform=\'translate(-53.485 -4.518)\'  />\n' +
            '                            <path id=\'Path_38\' data-name=\'Path 38\'\n' +
            '                                  d=\'M90.611,5.392a1.351,1.351,0,0,1,.39-1,1.316,1.316,0,0,1,.97-.39,1.334,1.334,0,0,1,1.388,1.387,1.344,1.344,0,0,1-1.388,1.374,1.3,1.3,0,0,1-.97-.4A1.339,1.339,0,0,1,90.611,5.392Zm2.464,16.736H90.894V9.074h2.181V22.128Z\'\n' +
            '                                  transform=\'translate(-57.995 -3.863)\'  />\n' +
            '                            <path id=\'Path_39\' data-name=\'Path 39\'\n' +
            '                                  d=\'M113.291,10.949a3.944,3.944,0,0,1,1.656.361,4.1,4.1,0,0,1,1.4,1.062,5.119,5.119,0,0,1,.956,1.7,6.808,6.808,0,0,1,.346,2.244v7.829h-2.18V16.315a3.626,3.626,0,0,0-.751-2.493,2.4,2.4,0,0,0-1.855-.835,2.561,2.561,0,0,0-1.933.892,3.44,3.44,0,0,0-.828,2.436v7.829h-2.166V16.315a3.606,3.606,0,0,0-.757-2.493,2.418,2.418,0,0,0-1.862-.835,2.35,2.35,0,0,0-1.026.241,2.659,2.659,0,0,0-.886.695,3.555,3.555,0,0,0-.609,1.062,3.785,3.785,0,0,0-.226,1.331v7.829h-2.181V11.09h1.6l.58,1.459a3.806,3.806,0,0,1,.779-.779,4.335,4.335,0,0,1,.991-.574,3.612,3.612,0,0,1,1.416-.248,3.517,3.517,0,0,1,1.67.361,4.669,4.669,0,0,1,1.119.8,4.321,4.321,0,0,1,.835,1.162,5.471,5.471,0,0,1,.935-1.162,5.66,5.66,0,0,1,1.211-.8,3.875,3.875,0,0,1,1.763-.361Z\'\n' +
            '                                  transform=\'translate(-60.835 -5.879)\'  />\n' +
            '                            <path id=\'Path_40\' data-name=\'Path 40\'\n' +
            '                                  d=\'M135.515,10.948a5.356,5.356,0,0,1,1.99.361,4.91,4.91,0,0,1,1.565.969,4.353,4.353,0,0,1,1.013,1.423,4.266,4.266,0,0,1,.361,1.748v8.694h-1.586l-.58-1.444a5.074,5.074,0,0,1-2.209,1.359,5.749,5.749,0,0,1-1.713.226,4.95,4.95,0,0,1-1.785-.3,4.045,4.045,0,0,1-1.359-.843,3.919,3.919,0,0,1-.885-1.246,3.641,3.641,0,0,1-.319-1.515,3.812,3.812,0,0,1,.375-1.7,3.3,3.3,0,0,1,1.3-1.331,7.678,7.678,0,0,1,2.542-.871,22.339,22.339,0,0,1,4.05-.305v-.722a2.317,2.317,0,0,0-.772-1.728,2.766,2.766,0,0,0-1.99-.736,2.7,2.7,0,0,0-1.8.5,2.635,2.635,0,0,0-.807,1.381h-2.322a4.522,4.522,0,0,1,.5-1.523,4.351,4.351,0,0,1,1-1.253,4.615,4.615,0,0,1,1.472-.843,5.783,5.783,0,0,1,1.954-.3ZM134.354,22.26a4.449,4.449,0,0,0,1.592-.276,4.184,4.184,0,0,0,1.239-.708,3.366,3.366,0,0,0,.8-.969,2.281,2.281,0,0,0,.29-1.091V18.2a24.443,24.443,0,0,0-3.158.162,6.752,6.752,0,0,0-1.848.467,1.748,1.748,0,0,0-.871.723,2.108,2.108,0,0,0-.213.969,1.6,1.6,0,0,0,.588,1.2,2.248,2.248,0,0,0,1.58.538Z\'\n' +
            '                                  transform=\'translate(-69.437 -5.878)\'  />\n' +
            '                            <path id=\'Path_41\' data-name=\'Path 41\'\n' +
            '                                  d=\'M155.44,17.533l4.644,6.669H157.62l-3.483-5.069L150.654,24.2H148.19l4.644-6.669-4.361-6.385h2.477l3.186,4.785,3.185-4.785h2.464l-4.346,6.385Z\'\n' +
            '                                  transform=\'translate(-74.717 -5.938)\'  />\n' +
            '                          </g>\n' +
            '                          <g id=\'Group_37\' data-name=\'Group 37\'>\n' +
            '                            <path id=\'Path_42\' data-name=\'Path 42\'\n' +
            '                                  d=\'M17.136,21.55l-2.615-4.5-2.615,4.5-2.9-5.924L14.521,5.384l5.511,10.233-2.9,5.932Zm-6.535-5.9,1.413,2.884,2.508-4.318,2.506,4.316,1.409-2.887L14.521,8.377,10.6,15.654Z\'\n' +
            '                                  transform=\'translate(-2.615 -1.564)\'  />\n' +
            '                            <path id=\'Path_43\' data-name=\'Path 43\'\n' +
            '                                  d=\'M11.9,23.806a11.9,11.9,0,1,1,11.9-11.9A11.916,11.916,0,0,1,11.9,23.806Zm0-22.387A10.484,10.484,0,1,0,22.387,11.9,10.5,10.5,0,0,0,11.9,1.419Z\'\n' +
            '                                   />\n' +
            '                          </g>\n' +
            '                        </g>\n' +
            '                      </g>\n' +
            '                    </g>\n' +
            '                  </g>\n' +
            '                </svg>' // Remplacez '<svg>...</svg>' par le code SVG réel
    },
    {
        name: 'apache',
        link: 'https://www.apacheconseil.com/',
        expertises: ['Stratégie', 'Branding', 'Social medias', 'Création'],
        logoMobile: `<svg xmlns="http://www.w3.org/2000/svg" width="259" height="59" viewBox="0 0 259 59">
  <g id="Groupe_22" data-name="Groupe 22" transform="translate(-3553.458 0.073)">
    <rect id="Rectangle_21" data-name="Rectangle 21" width="259" height="59" transform="translate(3553.458 -0.073)" fill="none"/>
    <g id="Calque_1" data-name="Calque 1" transform="translate(3601.826 9.683)">
      <path id="Tracé_44" data-name="Tracé 44" d="M24.868,5.994a7.234,7.234,0,0,1,3.179,2.65,7.307,7.307,0,0,1,1.116,4.068,7.321,7.321,0,0,1-1.116,4.052,7.087,7.087,0,0,1-3.179,2.65A11.924,11.924,0,0,1,20,20.333H15.753v5.814h-4.88V5.059H20A11.721,11.721,0,0,1,24.868,5.994Zm-1.792,9.414a3.306,3.306,0,0,0,1.144-2.7,3.341,3.341,0,0,0-1.144-2.727,5.126,5.126,0,0,0-3.345-.95H15.753v7.321h3.978a5.126,5.126,0,0,0,3.345-.95Z" transform="translate(16.89 7.859)" fill="#fff"/>
      <path id="Tracé_45" data-name="Tracé 45" d="M63.739,5.219V26.31h-4.88V17.664H49.943V26.31h-4.88V5.219h4.88v8.316h8.916V5.219Z" transform="translate(70.001 8.107)" fill="#fff"/>
      <path id="Tracé_46" data-name="Tracé 46" d="M29.365,4.778l-.276.718-1.79,4.79,3.345,8.076h-6.69l-1.366,3.7h9.593l1.867,4.52H39.17Z" transform="translate(35.088 7.422)" fill="#fff"/>
      <rect id="Rectangle_28" data-name="Rectangle 28" width="14.148" height="3.917" transform="translate(148.115 13.326)" fill="#fff"/>
      <rect id="Rectangle_29" data-name="Rectangle 29" width="14.235" height="3.917" transform="translate(148.029 30.447)" fill="#fff"/>
      <rect id="Rectangle_30" data-name="Rectangle 30" width="13.362" height="3.917" transform="translate(148.902 21.548)" fill="#fff"/>
      <rect id="Rectangle_31" data-name="Rectangle 31" width="20.417" height="4.371" transform="translate(152.39 13.487) rotate(90)" fill="#fff"/>
      <path id="Tracé_47" data-name="Tracé 47" d="M43.764,27.386h-.01a11.354,11.354,0,0,1,.008-22.707h.01A11.283,11.283,0,0,1,51.8,8.011L48.73,11.073a6.975,6.975,0,0,0-4.961-2.061h-.005a7.018,7.018,0,0,0-.008,14.036h.008A6.975,6.975,0,0,0,48.723,21l3.062,3.069a11.28,11.28,0,0,1-8.02,3.317Z" transform="translate(50.344 7.268)" fill="#fff"/>
      <path id="Tracé_48" data-name="Tracé 48" d="M6.792,4.69,4.711,10.422,8.056,18.5H1.369L0,22.2H9.593l1.869,4.52h5.12Z" transform="translate(0 7.285)" fill="#fff"/>
      <path id="Tracé_49" data-name="Tracé 49" d="M2.655,11.991l7.747-.026L15.718,0H7.634Z" transform="translate(4.124 0)" fill="#fff"/>
    </g>
  </g>
</svg>
`,
        logo: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'171\'\n' +
            '                   height=\'39\' viewBox=\'0 0 171 39\'>\n' +
            '                <g id=\'Group_40\' data-name=\'Group 40\' transform=\'translate(-2021)\'>\n' +
            '                  <g id=\'Group_22\' data-name=\'Group 22\' transform=\'translate(-334)\'>\n' +
            '                    <rect id=\'Rectangle_21\' data-name=\'Rectangle 21\' width=\'171\' height=\'39\'\n' +
            '                          transform=\'translate(2355)\' fill=\'none\' />\n' +
            '                    <g id=\'Calque_1\' data-name=\'Calque 1\' transform=\'translate(2386.738 5.416)\'>\n' +
            '                      <path id=\'Path_44\' data-name=\'Path 44\'\n' +
            '                            d=\'M20.147,5.678a4.794,4.794,0,0,1,2.107,1.756,4.842,4.842,0,0,1,.739,2.7,4.851,4.851,0,0,1-.739,2.685,4.7,4.7,0,0,1-2.107,1.756,7.9,7.9,0,0,1-3.225.609H14.106v3.853H10.873V5.059h6.049A7.767,7.767,0,0,1,20.147,5.678Zm-1.188,6.238a2.19,2.19,0,0,0,.758-1.787,2.214,2.214,0,0,0-.758-1.807,3.4,3.4,0,0,0-2.217-.629H14.106v4.851h2.636a3.4,3.4,0,0,0,2.217-.629Z\'\n' +
            '                            transform=\'translate(7.524 3.501)\'  />\n' +
            '                      <path id=\'Path_45\' data-name=\'Path 45\'\n' +
            '                            d=\'M57.438,5.219V19.195H54.2V13.466H48.3v5.729H45.063V5.219H48.3V10.73H54.2V5.219Z\'\n' +
            '                            transform=\'translate(31.184 3.612)\'  />\n' +
            '                      <path id=\'Path_46\' data-name=\'Path 46\'\n' +
            '                            d=\'M27.079,4.778l-.183.475L25.71,8.428l2.217,5.352H23.493l-.905,2.455h6.357l1.237,2.995h3.394Z\'\n' +
            '                            transform=\'translate(15.631 3.306)\'  />\n' +
            '                      <rect id=\'Rectangle_28\' data-name=\'Rectangle 28\' width=\'9.375\' height=\'2.596\'\n' +
            '                            transform=\'translate(98.148 8.831)\'  />\n' +
            '                      <rect id=\'Rectangle_29\' data-name=\'Rectangle 29\' width=\'9.433\' height=\'2.596\'\n' +
            '                            transform=\'translate(98.091 20.175)\'  />\n' +
            '                      <rect id=\'Rectangle_30\' data-name=\'Rectangle 30\' width=\'8.854\' height=\'2.596\'\n' +
            '                            transform=\'translate(98.669 14.279)\'  />\n' +
            '                      <rect id=\'Rectangle_31\' data-name=\'Rectangle 31\' width=\'13.529\' height=\'2.897\'\n' +
            '                            transform=\'translate(100.981 8.937) rotate(90)\'  />\n' +
            '                      <path id=\'Path_47\' data-name=\'Path 47\'\n' +
            '                            d=\'M39.933,19.726h-.007a7.524,7.524,0,0,1,.005-15.047h.007a7.477,7.477,0,0,1,5.318,2.208L43.224,8.916A4.622,4.622,0,0,0,39.937,7.55h0a4.65,4.65,0,0,0-.005,9.3h.005a4.622,4.622,0,0,0,3.286-1.359l2.029,2.034a7.475,7.475,0,0,1-5.315,2.2Z\'\n' +
            '                            transform=\'translate(22.427 3.238)\'  />\n' +
            '                      <path id=\'Path_48\' data-name=\'Path 48\'\n' +
            '                            d=\'M4.5,4.69l-1.379,3.8L5.338,13.84H.907L0,16.3H6.357L7.6,19.29h3.392Z\'\n' +
            '                            transform=\'translate(0 3.246)\'  />\n' +
            '                      <path id=\'Path_49\' data-name=\'Path 49\' d=\'M2.655,7.946l5.134-.017L11.311,0H5.954Z\'\n' +
            '                            transform=\'translate(1.837 0)\'  />\n' +
            '                    </g>\n' +
            '                  </g>\n' +
            '                </g>\n' +
            '              </svg>' // Remplacez '<svg>...</svg>' par le code SVG réel
    },
    {
        name: 'yuge',
        link: 'https://www.yuge-films.com/',
        expertises: [false, 'Production de films', 'Motion design', 'Création 3D'],
        logoMobile: `<svg xmlns="http://www.w3.org/2000/svg" width="259" height="59" viewBox="0 0 259 59">
  <g id="Group_22" data-name="Group 22" transform="translate(-3553.458 -0.053)">
    <g id="Group_5" data-name="Group 5" transform="translate(3600.712 5.926)">
      <path id="Path_1" data-name="Path 1" d="M137.2,87.866V97.1h2.138V93.731h4.277V92.017h-4.277V89.581h4.837V87.866Z" transform="translate(-78.242 -50.11)" fill="#fff"/>
      <rect id="Rectangle_2" data-name="Rectangle 2" width="2.138" height="9.231" transform="translate(71.333 37.757)" fill="#fff"/>
      <path id="Path_2" data-name="Path 2" d="M185.279,87.866V97.1h6.767V95.355h-4.628V87.866Z" transform="translate(-105.664 -50.11)" fill="#fff"/>
      <path id="Path_3" data-name="Path 3" d="M221.643,87.887l-3.4,5.739-3.456-5.739h-1.769v9.231h2V91.695l2.707,4.44h.966l2.716-4.566.009,5.54v.009h2l-.027-9.231Z" transform="translate(-121.482 -50.121)" fill="#fff"/>
      <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
        <path id="Path_4" data-name="Path 4" d="M251.489,96.713a4.88,4.88,0,0,1-1.642-.776l.722-1.606a5.137,5.137,0,0,0,1.408.7,5.418,5.418,0,0,0,1.633.262,2.614,2.614,0,0,0,1.345-.27.814.814,0,0,0,.433-.722.675.675,0,0,0-.262-.55,1.977,1.977,0,0,0-.659-.352,10.546,10.546,0,0,0-1.092-.289,15.221,15.221,0,0,1-1.732-.505,2.85,2.85,0,0,1-1.155-.8,2.162,2.162,0,0,1-.478-1.479,2.618,2.618,0,0,1,.433-1.462,2.99,2.99,0,0,1,1.317-1.038,5.413,5.413,0,0,1,2.147-.379,7.324,7.324,0,0,1,1.724.208,5.345,5.345,0,0,1,1.479.6l-.658,1.624a5.113,5.113,0,0,0-2.554-.722,2.45,2.45,0,0,0-1.326.289.883.883,0,0,0-.433.767.742.742,0,0,0,.5.7,7.539,7.539,0,0,0,1.507.451,15.121,15.121,0,0,1,1.733.5,2.829,2.829,0,0,1,1.155.794,2.1,2.1,0,0,1,.478,1.462,2.5,2.5,0,0,1-.442,1.443,3.071,3.071,0,0,1-1.326,1.038,5.444,5.444,0,0,1-2.148.379,6.911,6.911,0,0,1-2.1-.28" transform="translate(-142.486 -49.87)" fill="#fff"/>
        <path id="Path_5" data-name="Path 5" d="M130.922,3.189h6.087V18.081c0,9.238-5.435,10.144-16.594,10.144-9.275,0-13.261-1.63-13.261-10.108V3.189h6.377V18.334c0,3.877,1.848,4.855,8.079,4.855,6.523,0,9.312-.87,9.312-4.819Z" transform="translate(-61.109 -1.819)" fill="#fff"/>
        <path id="Path_6" data-name="Path 6" d="M225.548,6.237c-7.21,0-9.855,2.21-9.855,8.225,0,5.942,2.464,8.225,10.4,8.225,3.949,0,7.861-.725,9.673-1.775V16.258H222.759V12.019h18.587V24.026c-3.841,2.391-9.782,3.478-15.036,3.478-11.014,0-16.739-3.188-16.739-13.043s5.689-13.044,15.978-13.044h10.967V6.246Z" transform="translate(-119.517 -0.808)" fill="#fff"/>
        <path id="Path_7" data-name="Path 7" d="M318.059,27.718V3.188h27.453V7.971h-21.33v4.964h21.33v4.42h-21.33v5.109h21.33v5.254Z" transform="translate(-181.387 -1.818)" fill="#fff"/>
        <path id="Path_8" data-name="Path 8" d="M34.7,4.469,30.377.111l-12.849,12L4.631,0,0,4.459l14.381,13.2v8.395h6.272V17.645Z" transform="translate(0 0)" fill="#fff"/>
      </g>
    </g>
    <rect id="Rectangle_21" data-name="Rectangle 21" width="259" height="59" transform="translate(3553.458 0.053)" fill="none"/>
  </g>
</svg>

`,
        logo: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'171\'\n' +
            '                   height=\'39\' viewBox=\'0 0 171 39\'>\n' +
            '                <g id=\'Group_23\' data-name=\'Group 23\' transform=\'translate(-2021)\'>\n' +
            '                  <g id=\'Group_22\' data-name=\'Group 22\' transform=\'translate(-334)\'>\n' +
            '                    <g id=\'Group_5\' data-name=\'Group 5\' transform=\'translate(2391.682 7.559)\'>\n' +
            '                      <path id=\'Path_1\' data-name=\'Path 1\'\n' +
            '                            d=\'M137.2,87.866v5.477h1.269v-2H141V90.329h-2.538V88.884h2.87V87.866Z\'\n' +
            '                            transform=\'translate(-102.212 -65.461)\'  />\n' +
            '                      <rect id=\'Rectangle_2\' data-name=\'Rectangle 2\' width=\'1.269\' height=\'5.477\'\n' +
            '                            transform=\'translate(42.329 22.405)\'  />\n' +
            '                      <path id=\'Path_2\' data-name=\'Path 2\' d=\'M185.279,87.866v5.477h4.016V92.31h-2.747V87.866Z\'\n' +
            '                            transform=\'translate(-138.035 -65.461)\'  />\n' +
            '                      <path id=\'Path_3\' data-name=\'Path 3\'\n' +
            '                            d=\'M218.136,87.887l-2.018,3.405-2.051-3.405h-1.05v5.477h1.189V90.146l1.606,2.635h.573L218,90.071,218,93.359v.005h1.188l-.016-5.477Z\'\n' +
            '                            transform=\'translate(-158.7 -65.477)\'  />\n' +
            '                      <g id=\'Group_4\' data-name=\'Group 4\' transform=\'translate(0 0)\'>\n' +
            '                        <path id=\'Path_4\' data-name=\'Path 4\'\n' +
            '                              d=\'M250.822,92.945a2.9,2.9,0,0,1-.975-.461l.428-.953a3.049,3.049,0,0,0,.835.417,3.215,3.215,0,0,0,.969.155,1.551,1.551,0,0,0,.8-.16.483.483,0,0,0,.257-.428.4.4,0,0,0-.155-.327,1.173,1.173,0,0,0-.391-.209,6.26,6.26,0,0,0-.648-.171,9.031,9.031,0,0,1-1.028-.3,1.691,1.691,0,0,1-.685-.477,1.283,1.283,0,0,1-.284-.878,1.554,1.554,0,0,1,.257-.867,1.774,1.774,0,0,1,.782-.616,3.212,3.212,0,0,1,1.274-.225,4.346,4.346,0,0,1,1.023.123,3.172,3.172,0,0,1,.878.359l-.391.964a3.034,3.034,0,0,0-1.515-.428,1.454,1.454,0,0,0-.787.171.524.524,0,0,0-.257.455.44.44,0,0,0,.294.418,4.474,4.474,0,0,0,.894.268,8.974,8.974,0,0,1,1.028.3,1.679,1.679,0,0,1,.685.471,1.247,1.247,0,0,1,.284.867,1.485,1.485,0,0,1-.262.857,1.822,1.822,0,0,1-.787.616,3.231,3.231,0,0,1-1.274.225,4.1,4.1,0,0,1-1.247-.166\'\n' +
            '                              transform=\'translate(-186.139 -65.148)\'  />\n' +
            '                        <path id=\'Path_5\' data-name=\'Path 5\'\n' +
            '                              d=\'M121.258,3.189h3.612v8.837c0,5.482-3.225,6.02-9.847,6.02-5.5,0-7.869-.967-7.869-6V3.189h3.784v8.987c0,2.3,1.1,2.881,4.794,2.881,3.87,0,5.526-.516,5.526-2.859Z\'\n' +
            '                              transform=\'translate(-79.831 -2.376)\'  />\n' +
            '                        <path id=\'Path_6\' data-name=\'Path 6\'\n' +
            '                              d=\'M219.052,4.277c-4.279,0-5.848,1.311-5.848,4.88,0,3.526,1.462,4.88,6.171,4.88a13.363,13.363,0,0,0,5.74-1.053V10.224H217.4V7.708h11.029v7.125c-2.279,1.419-5.8,2.064-8.922,2.064-6.536,0-9.933-1.892-9.933-7.74s3.376-7.74,9.482-7.74h6.508V4.282Z\'\n' +
            '                              transform=\'translate(-156.132 -1.056)\'  />\n' +
            '                        <path id=\'Path_7\' data-name=\'Path 7\'\n' +
            '                              d=\'M318.058,17.744V3.188h16.29V6.026H321.692V8.972h12.657V11.6H321.692v3.032h12.657v3.117Z\'\n' +
            '                              transform=\'translate(-236.957 -2.375)\'  />\n' +
            '                        <path id=\'Path_8\' data-name=\'Path 8\'\n' +
            '                              d=\'M20.592,2.652,18.026.066,10.4,7.189,2.748,0,0,2.646l8.533,7.831v4.981h3.722V10.471Z\'\n' +
            '                              transform=\'translate(0 0)\'  />\n' +
            '                      </g>\n' +
            '                    </g>\n' +
            '                    <rect id=\'Rectangle_21\' data-name=\'Rectangle 21\' width=\'171\' height=\'39\'\n' +
            '                          transform=\'translate(2355)\' fill=\'none\' />\n' +
            '                  </g>\n' +
            '                </g>\n' +
            '              </svg>' // Remplacez '<svg>...</svg>' par le code SVG réel
    }

]

export default agenciesData

// {
// name: 'altimax',
//   expertises: ['Expertise', 'Expertise', 'Expertise', 'Expertise'],
//   logo: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'171\'\n' +
// '                     height=\'39\' viewBox=\'0 0 171 39\'>\n' +
// '                  <g id=\'Group_39\' data-name=\'Group 39\' transform=\'translate(-2021)\'>\n' +
// '                    <g id=\'Group_22\' data-name=\'Group 22\' transform=\'translate(-334)\'>\n' +
// '                      <rect id=\'Rectangle_21\' data-name=\'Rectangle 21\' width=\'171\' height=\'39\'\n' +
// '                            transform=\'translate(2355)\' fill=\'none\' />\n' +
// '                      <g id=\'Calque_1\' data-name=\'Calque 1\' transform=\'translate(2382.248 7.533)\'>\n' +
// '                        <g id=\'Group_38\' data-name=\'Group 38\' transform=\'translate(0 0)\'>\n' +
// '                          <g id=\'Group_36\' data-name=\'Group 36\' transform=\'translate(31.679 2.7)\'>\n' +
// '                            <path id=\'Path_35\' data-name=\'Path 35\'\n' +
// '                                  d=\'M50.153,10.948a5.354,5.354,0,0,1,1.99.361,4.91,4.91,0,0,1,1.565.969A4.34,4.34,0,0,1,54.719,13.7a4.266,4.266,0,0,1,.361,1.748v8.694H53.494l-.58-1.444a5.067,5.067,0,0,1-2.208,1.359,5.748,5.748,0,0,1-1.714.226,4.943,4.943,0,0,1-1.784-.3,4.063,4.063,0,0,1-1.36-.843,3.92,3.92,0,0,1-.885-1.246,3.653,3.653,0,0,1-.319-1.515,3.8,3.8,0,0,1,.375-1.7,3.3,3.3,0,0,1,1.3-1.331,7.678,7.678,0,0,1,2.542-.871,22.4,22.4,0,0,1,4.05-.305v-.722a2.316,2.316,0,0,0-.771-1.728,2.766,2.766,0,0,0-1.99-.736,2.7,2.7,0,0,0-1.8.5,2.645,2.645,0,0,0-.807,1.381H45.226a4.538,4.538,0,0,1,.5-1.523,4.365,4.365,0,0,1,1.005-1.253,4.607,4.607,0,0,1,1.472-.843,5.759,5.759,0,0,1,1.953-.3ZM48.992,22.26a4.453,4.453,0,0,0,1.593-.276,4.2,4.2,0,0,0,1.239-.708,3.34,3.34,0,0,0,.8-.969,2.282,2.282,0,0,0,.29-1.091V18.2a24.459,24.459,0,0,0-3.158.162,6.752,6.752,0,0,0-1.848.467,1.746,1.746,0,0,0-.871.723,2.108,2.108,0,0,0-.212.969,1.6,1.6,0,0,0,.588,1.2,2.245,2.245,0,0,0,1.579.538Z\'\n' +
// '                                  transform=\'translate(-44.645 -5.878)\'  />\n' +
// '                            <path id=\'Path_36\' data-name=\'Path 36\'\n' +
// '                                  d=\'M67.861,17.865a2.349,2.349,0,0,0,.595,1.714,2.367,2.367,0,0,0,2.1.552.8.8,0,0,0,.212-.085v1.883a1.507,1.507,0,0,1-.326.141,2.852,2.852,0,0,1-.977.141,3.377,3.377,0,0,1-1.437-.318,3.834,3.834,0,0,1-1.211-.885,4.187,4.187,0,0,1-.828-1.36,4.947,4.947,0,0,1-.3-1.785V3.805h2.181V17.864Z\'\n' +
// '                                  transform=\'translate(-50.754 -3.805)\'  />\n' +
// '                            <path id=\'Path_37\' data-name=\'Path 37\'\n' +
// '                                  d=\'M77.688,6.26h1.883V9.729h2.9v2.039h-2.9v6.811a2.349,2.349,0,0,0,.595,1.714,2.116,2.116,0,0,0,1.586.608,2.292,2.292,0,0,0,.524-.056.6.6,0,0,0,.2-.085v1.883a1.582,1.582,0,0,1-.312.141,2.9,2.9,0,0,1-.991.141,3.333,3.333,0,0,1-1.431-.318,3.87,3.87,0,0,1-1.2-.885,4.175,4.175,0,0,1-.828-1.36,4.947,4.947,0,0,1-.3-1.785V11.767H75.083V9.728H76.1a1.394,1.394,0,0,0,1.586-1.586V6.26Z\'\n' +
// '                                  transform=\'translate(-53.485 -4.518)\'  />\n' +
// '                            <path id=\'Path_38\' data-name=\'Path 38\'\n' +
// '                                  d=\'M90.611,5.392a1.351,1.351,0,0,1,.39-1,1.316,1.316,0,0,1,.97-.39,1.334,1.334,0,0,1,1.388,1.387,1.344,1.344,0,0,1-1.388,1.374,1.3,1.3,0,0,1-.97-.4A1.339,1.339,0,0,1,90.611,5.392Zm2.464,16.736H90.894V9.074h2.181V22.128Z\'\n' +
// '                                  transform=\'translate(-57.995 -3.863)\'  />\n' +
// '                            <path id=\'Path_39\' data-name=\'Path 39\'\n' +
// '                                  d=\'M113.291,10.949a3.944,3.944,0,0,1,1.656.361,4.1,4.1,0,0,1,1.4,1.062,5.119,5.119,0,0,1,.956,1.7,6.808,6.808,0,0,1,.346,2.244v7.829h-2.18V16.315a3.626,3.626,0,0,0-.751-2.493,2.4,2.4,0,0,0-1.855-.835,2.561,2.561,0,0,0-1.933.892,3.44,3.44,0,0,0-.828,2.436v7.829h-2.166V16.315a3.606,3.606,0,0,0-.757-2.493,2.418,2.418,0,0,0-1.862-.835,2.35,2.35,0,0,0-1.026.241,2.659,2.659,0,0,0-.886.695,3.555,3.555,0,0,0-.609,1.062,3.785,3.785,0,0,0-.226,1.331v7.829h-2.181V11.09h1.6l.58,1.459a3.806,3.806,0,0,1,.779-.779,4.335,4.335,0,0,1,.991-.574,3.612,3.612,0,0,1,1.416-.248,3.517,3.517,0,0,1,1.67.361,4.669,4.669,0,0,1,1.119.8,4.321,4.321,0,0,1,.835,1.162,5.471,5.471,0,0,1,.935-1.162,5.66,5.66,0,0,1,1.211-.8,3.875,3.875,0,0,1,1.763-.361Z\'\n' +
// '                                  transform=\'translate(-60.835 -5.879)\'  />\n' +
// '                            <path id=\'Path_40\' data-name=\'Path 40\'\n' +
// '                                  d=\'M135.515,10.948a5.356,5.356,0,0,1,1.99.361,4.91,4.91,0,0,1,1.565.969,4.353,4.353,0,0,1,1.013,1.423,4.266,4.266,0,0,1,.361,1.748v8.694h-1.586l-.58-1.444a5.074,5.074,0,0,1-2.209,1.359,5.749,5.749,0,0,1-1.713.226,4.95,4.95,0,0,1-1.785-.3,4.045,4.045,0,0,1-1.359-.843,3.919,3.919,0,0,1-.885-1.246,3.641,3.641,0,0,1-.319-1.515,3.812,3.812,0,0,1,.375-1.7,3.3,3.3,0,0,1,1.3-1.331,7.678,7.678,0,0,1,2.542-.871,22.339,22.339,0,0,1,4.05-.305v-.722a2.317,2.317,0,0,0-.772-1.728,2.766,2.766,0,0,0-1.99-.736,2.7,2.7,0,0,0-1.8.5,2.635,2.635,0,0,0-.807,1.381h-2.322a4.522,4.522,0,0,1,.5-1.523,4.351,4.351,0,0,1,1-1.253,4.615,4.615,0,0,1,1.472-.843,5.783,5.783,0,0,1,1.954-.3ZM134.354,22.26a4.449,4.449,0,0,0,1.592-.276,4.184,4.184,0,0,0,1.239-.708,3.366,3.366,0,0,0,.8-.969,2.281,2.281,0,0,0,.29-1.091V18.2a24.443,24.443,0,0,0-3.158.162,6.752,6.752,0,0,0-1.848.467,1.748,1.748,0,0,0-.871.723,2.108,2.108,0,0,0-.213.969,1.6,1.6,0,0,0,.588,1.2,2.248,2.248,0,0,0,1.58.538Z\'\n' +
// '                                  transform=\'translate(-69.437 -5.878)\'  />\n' +
// '                            <path id=\'Path_41\' data-name=\'Path 41\'\n' +
// '                                  d=\'M155.44,17.533l4.644,6.669H157.62l-3.483-5.069L150.654,24.2H148.19l4.644-6.669-4.361-6.385h2.477l3.186,4.785,3.185-4.785h2.464l-4.346,6.385Z\'\n' +
// '                                  transform=\'translate(-74.717 -5.938)\'  />\n' +
// '                          </g>\n' +
// '                          <g id=\'Group_37\' data-name=\'Group 37\'>\n' +
// '                            <path id=\'Path_42\' data-name=\'Path 42\'\n' +
// '                                  d=\'M17.136,21.55l-2.615-4.5-2.615,4.5-2.9-5.924L14.521,5.384l5.511,10.233-2.9,5.932Zm-6.535-5.9,1.413,2.884,2.508-4.318,2.506,4.316,1.409-2.887L14.521,8.377,10.6,15.654Z\'\n' +
// '                                  transform=\'translate(-2.615 -1.564)\'  />\n' +
// '                            <path id=\'Path_43\' data-name=\'Path 43\'\n' +
// '                                  d=\'M11.9,23.806a11.9,11.9,0,1,1,11.9-11.9A11.916,11.916,0,0,1,11.9,23.806Zm0-22.387A10.484,10.484,0,1,0,22.387,11.9,10.5,10.5,0,0,0,11.9,1.419Z\'\n' +
// '                                   />\n' +
// '                          </g>\n' +
// '                        </g>\n' +
// '                      </g>\n' +
// '                    </g>\n' +
// '                  </g>\n' +
// '                </svg>' // Remplacez '<svg>...</svg>' par le code SVG réel
// },
// {
//   name: 'apache',
//     expertises: ['Expertise', 'Expertise', 'Expertise', 'Expertise'],
//   logo: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'171\'\n' +
// '                   height=\'39\' viewBox=\'0 0 171 39\'>\n' +
// '                <g id=\'Group_40\' data-name=\'Group 40\' transform=\'translate(-2021)\'>\n' +
// '                  <g id=\'Group_22\' data-name=\'Group 22\' transform=\'translate(-334)\'>\n' +
// '                    <rect id=\'Rectangle_21\' data-name=\'Rectangle 21\' width=\'171\' height=\'39\'\n' +
// '                          transform=\'translate(2355)\' fill=\'none\' />\n' +
// '                    <g id=\'Calque_1\' data-name=\'Calque 1\' transform=\'translate(2386.738 5.416)\'>\n' +
// '                      <path id=\'Path_44\' data-name=\'Path 44\'\n' +
// '                            d=\'M20.147,5.678a4.794,4.794,0,0,1,2.107,1.756,4.842,4.842,0,0,1,.739,2.7,4.851,4.851,0,0,1-.739,2.685,4.7,4.7,0,0,1-2.107,1.756,7.9,7.9,0,0,1-3.225.609H14.106v3.853H10.873V5.059h6.049A7.767,7.767,0,0,1,20.147,5.678Zm-1.188,6.238a2.19,2.19,0,0,0,.758-1.787,2.214,2.214,0,0,0-.758-1.807,3.4,3.4,0,0,0-2.217-.629H14.106v4.851h2.636a3.4,3.4,0,0,0,2.217-.629Z\'\n' +
// '                            transform=\'translate(7.524 3.501)\'  />\n' +
// '                      <path id=\'Path_45\' data-name=\'Path 45\'\n' +
// '                            d=\'M57.438,5.219V19.195H54.2V13.466H48.3v5.729H45.063V5.219H48.3V10.73H54.2V5.219Z\'\n' +
// '                            transform=\'translate(31.184 3.612)\'  />\n' +
// '                      <path id=\'Path_46\' data-name=\'Path 46\'\n' +
// '                            d=\'M27.079,4.778l-.183.475L25.71,8.428l2.217,5.352H23.493l-.905,2.455h6.357l1.237,2.995h3.394Z\'\n' +
// '                            transform=\'translate(15.631 3.306)\'  />\n' +
// '                      <rect id=\'Rectangle_28\' data-name=\'Rectangle 28\' width=\'9.375\' height=\'2.596\'\n' +
// '                            transform=\'translate(98.148 8.831)\'  />\n' +
// '                      <rect id=\'Rectangle_29\' data-name=\'Rectangle 29\' width=\'9.433\' height=\'2.596\'\n' +
// '                            transform=\'translate(98.091 20.175)\'  />\n' +
// '                      <rect id=\'Rectangle_30\' data-name=\'Rectangle 30\' width=\'8.854\' height=\'2.596\'\n' +
// '                            transform=\'translate(98.669 14.279)\'  />\n' +
// '                      <rect id=\'Rectangle_31\' data-name=\'Rectangle 31\' width=\'13.529\' height=\'2.897\'\n' +
// '                            transform=\'translate(100.981 8.937) rotate(90)\'  />\n' +
// '                      <path id=\'Path_47\' data-name=\'Path 47\'\n' +
// '                            d=\'M39.933,19.726h-.007a7.524,7.524,0,0,1,.005-15.047h.007a7.477,7.477,0,0,1,5.318,2.208L43.224,8.916A4.622,4.622,0,0,0,39.937,7.55h0a4.65,4.65,0,0,0-.005,9.3h.005a4.622,4.622,0,0,0,3.286-1.359l2.029,2.034a7.475,7.475,0,0,1-5.315,2.2Z\'\n' +
// '                            transform=\'translate(22.427 3.238)\'  />\n' +
// '                      <path id=\'Path_48\' data-name=\'Path 48\'\n' +
// '                            d=\'M4.5,4.69l-1.379,3.8L5.338,13.84H.907L0,16.3H6.357L7.6,19.29h3.392Z\'\n' +
// '                            transform=\'translate(0 3.246)\'  />\n' +
// '                      <path id=\'Path_49\' data-name=\'Path 49\' d=\'M2.655,7.946l5.134-.017L11.311,0H5.954Z\'\n' +
// '                            transform=\'translate(1.837 0)\'  />\n' +
// '                    </g>\n' +
// '                  </g>\n' +
// '                </g>\n' +
// '              </svg>' // Remplacez '<svg>...</svg>' par le code SVG réel
// },
// {
//   name: 'yuge',
//     expertises: ['Expertise', 'Expertise', 'Expertise', 'Expertise'],
//   logo: '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'171\'\n' +
// '                   height=\'39\' viewBox=\'0 0 171 39\'>\n' +
// '                <g id=\'Group_23\' data-name=\'Group 23\' transform=\'translate(-2021)\'>\n' +
// '                  <g id=\'Group_22\' data-name=\'Group 22\' transform=\'translate(-334)\'>\n' +
// '                    <g id=\'Group_5\' data-name=\'Group 5\' transform=\'translate(2391.682 7.559)\'>\n' +
// '                      <path id=\'Path_1\' data-name=\'Path 1\'\n' +
// '                            d=\'M137.2,87.866v5.477h1.269v-2H141V90.329h-2.538V88.884h2.87V87.866Z\'\n' +
// '                            transform=\'translate(-102.212 -65.461)\'  />\n' +
// '                      <rect id=\'Rectangle_2\' data-name=\'Rectangle 2\' width=\'1.269\' height=\'5.477\'\n' +
// '                            transform=\'translate(42.329 22.405)\'  />\n' +
// '                      <path id=\'Path_2\' data-name=\'Path 2\' d=\'M185.279,87.866v5.477h4.016V92.31h-2.747V87.866Z\'\n' +
// '                            transform=\'translate(-138.035 -65.461)\'  />\n' +
// '                      <path id=\'Path_3\' data-name=\'Path 3\'\n' +
// '                            d=\'M218.136,87.887l-2.018,3.405-2.051-3.405h-1.05v5.477h1.189V90.146l1.606,2.635h.573L218,90.071,218,93.359v.005h1.188l-.016-5.477Z\'\n' +
// '                            transform=\'translate(-158.7 -65.477)\'  />\n' +
// '                      <g id=\'Group_4\' data-name=\'Group 4\' transform=\'translate(0 0)\'>\n' +
// '                        <path id=\'Path_4\' data-name=\'Path 4\'\n' +
// '                              d=\'M250.822,92.945a2.9,2.9,0,0,1-.975-.461l.428-.953a3.049,3.049,0,0,0,.835.417,3.215,3.215,0,0,0,.969.155,1.551,1.551,0,0,0,.8-.16.483.483,0,0,0,.257-.428.4.4,0,0,0-.155-.327,1.173,1.173,0,0,0-.391-.209,6.26,6.26,0,0,0-.648-.171,9.031,9.031,0,0,1-1.028-.3,1.691,1.691,0,0,1-.685-.477,1.283,1.283,0,0,1-.284-.878,1.554,1.554,0,0,1,.257-.867,1.774,1.774,0,0,1,.782-.616,3.212,3.212,0,0,1,1.274-.225,4.346,4.346,0,0,1,1.023.123,3.172,3.172,0,0,1,.878.359l-.391.964a3.034,3.034,0,0,0-1.515-.428,1.454,1.454,0,0,0-.787.171.524.524,0,0,0-.257.455.44.44,0,0,0,.294.418,4.474,4.474,0,0,0,.894.268,8.974,8.974,0,0,1,1.028.3,1.679,1.679,0,0,1,.685.471,1.247,1.247,0,0,1,.284.867,1.485,1.485,0,0,1-.262.857,1.822,1.822,0,0,1-.787.616,3.231,3.231,0,0,1-1.274.225,4.1,4.1,0,0,1-1.247-.166\'\n' +
// '                              transform=\'translate(-186.139 -65.148)\'  />\n' +
// '                        <path id=\'Path_5\' data-name=\'Path 5\'\n' +
// '                              d=\'M121.258,3.189h3.612v8.837c0,5.482-3.225,6.02-9.847,6.02-5.5,0-7.869-.967-7.869-6V3.189h3.784v8.987c0,2.3,1.1,2.881,4.794,2.881,3.87,0,5.526-.516,5.526-2.859Z\'\n' +
// '                              transform=\'translate(-79.831 -2.376)\'  />\n' +
// '                        <path id=\'Path_6\' data-name=\'Path 6\'\n' +
// '                              d=\'M219.052,4.277c-4.279,0-5.848,1.311-5.848,4.88,0,3.526,1.462,4.88,6.171,4.88a13.363,13.363,0,0,0,5.74-1.053V10.224H217.4V7.708h11.029v7.125c-2.279,1.419-5.8,2.064-8.922,2.064-6.536,0-9.933-1.892-9.933-7.74s3.376-7.74,9.482-7.74h6.508V4.282Z\'\n' +
// '                              transform=\'translate(-156.132 -1.056)\'  />\n' +
// '                        <path id=\'Path_7\' data-name=\'Path 7\'\n' +
// '                              d=\'M318.058,17.744V3.188h16.29V6.026H321.692V8.972h12.657V11.6H321.692v3.032h12.657v3.117Z\'\n' +
// '                              transform=\'translate(-236.957 -2.375)\'  />\n' +
// '                        <path id=\'Path_8\' data-name=\'Path 8\'\n' +
// '                              d=\'M20.592,2.652,18.026.066,10.4,7.189,2.748,0,0,2.646l8.533,7.831v4.981h3.722V10.471Z\'\n' +
// '                              transform=\'translate(0 0)\'  />\n' +
// '                      </g>\n' +
// '                    </g>\n' +
// '                    <rect id=\'Rectangle_21\' data-name=\'Rectangle 21\' width=\'171\' height=\'39\'\n' +
// '                          transform=\'translate(2355)\' fill=\'none\' />\n' +
// '                  </g>\n' +
// '                </g>\n' +
// '              </svg>' // Remplacez '<svg>...</svg>' par le code SVG réel
// },