import { useLoader } from '@react-three/fiber'
import { EffectComposer, SSR, Bloom, LUT, Noise, ToneMapping } from '@react-three/postprocessing'
import { useControls } from 'leva'
import { LUTCubeLoader } from 'postprocessing'
import { Html } from '@react-three/drei'

export function Effects() {
  const props = {
    enabled: true,
    temporalResolve: true,
    STRETCH_MISSED_RAYS: false,
    USE_MRT: true,
    USE_NORMALMAP: true,
    USE_ROUGHNESSMAP: true,
    ENABLE_JITTERING: true,
    ENABLE_BLUR: true,
    temporalResolveMix: 1,
    temporalResolveCorrectionMix: 0.4,
    maxSamples: 0,
    resolutionScale: 1,
    blurMix: 1,
    blurExponent: 13,
    blurKernelSize: 6,
    blurSharpness:0,
    rayStep: 0.47,
    intensity: 1,
    maxRoughness: 0.79,
    jitter: 0.15,
    jitterSpread: 0.51,
    jitterRough: 0.51,
    roughnessFadeOut: 0.0,
    rayFadeOut: 0,
    MAX_STEPS: 20,
    NUM_BINARY_SEARCH_STEPS: 10,
    maxDepthDifference: 50,
    maxDepth: 1,
    thickness: 10,
    ior: 2
  }
  return (
    <>
      <EffectComposer  disableNormalPass>
        <SSR {...props} />
        <ToneMapping adaptive opacity={0.5} />
      </EffectComposer>
    </>
  )
}
