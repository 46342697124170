import { createRoot } from 'react-dom/client'
import { Preload, Stats } from '@react-three/drei'
import { Leva } from 'leva'

import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import './styles.css'
import App from './App'
import Overlay from './Overlay'
import OverlayMobile from './OverlayMobile'
import LoadingScreen from "./LoadingScreen";
import MobileViewer from "./MobileViewer";
createRoot(document.getElementById('root')).render(<>
  <BrowserView style={{ height: '100%' }}>
    <App />
  </BrowserView>
  <MobileView style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
    <MobileViewer/>
  </MobileView>
</>)
