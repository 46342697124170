import {useFrame} from '@react-three/fiber'

export default function Camera() {
    let prevMouseX = 0
    const smoothingFactor = 0.1
    let initialPositionSet = false
    let currentSpeed = 0 // Ajout de la variable de vitesse
    const maxSpeed = 0.05 // Vitesse maximale

    useFrame((state) => {
        const handleMouseMove = (event) => {
            const mouseX = (event.clientX / window.innerWidth) * 2 - 1
            const mouseY = -(event.clientY / window.innerHeight) * 2 + 1

            let deltaMouseX = ((mouseX - prevMouseX) / 2) * smoothingFactor
            let speed = Math.abs(deltaMouseX)

            // Limite la vitesse maximale
            if (speed > maxSpeed) {
                deltaMouseX *= maxSpeed / speed
            }

            // Le reste de votre logique
            if (-0.33 < mouseX) {
                if (0.33 > mouseX) {
                    deltaMouseX = ((mouseX - prevMouseX) / 2) * smoothingFactor
                    state.camera.position.lerp({x: (mouseX * 3.5), y: 1.858 + (mouseY / 2), z: 5.697}, 0.05)
                } else {
                    state.camera.position.lerp({
                        x: (0.33 * 3.5) + (mouseX * 0.5),
                        y: 1.858 + (mouseY / 2),
                        z: 5.697
                    }, 0.05)
                }
            } else {
                state.camera.position.lerp({
                    x: (-0.33 * 3.5) + (mouseX * 0.5),
                    y: 1.858 + (mouseY / 2),
                    z: 5.697
                }, 0.05)
            }

            state.camera.lookAt(0, 2, 0.5)

            if (deltaMouseX !== 0) {
                state.camera.rotateZ(deltaMouseX)
                currentSpeed = Math.abs(deltaMouseX)
            }

            prevMouseX += deltaMouseX
        }

        if (!initialPositionSet) {
            state.camera.position.lerp({x: (0 * 3.5), y: 1.858 + (0 / 2), z: 5.697}, 0.05)
        }

        if (
            state.camera.position.x < 0.2 && state.camera.position.x > -0.2 &&
            state.camera.position.y < 2 && state.camera.position.y > 1.7 &&
            state.camera.position.z < 5.9 && state.camera.position.z > 5.5
        ) {
            initialPositionSet = true
            window.addEventListener('mousemove', handleMouseMove)
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    })
}
