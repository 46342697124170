import Html, {useEffect, useRef, useState} from 'react'
import Lottie, {useLottie} from "lottie-react";

import loader from "./loader.json";
import Loader from "./Loader";

export default function LoadingScreen({isLoading, onButtonPressed}) {
    const [isLoaded, setIsLoaded] = useState(false);

    const lottieRef = useRef();


    // useEffect(() => {
    //     console.log(lottieRef.current.getDuration(true))
    //     console.log(lottieRef.current.getDuration(false))
    //     if (!isLoading) {
    //         lottieRef.current.goToAndStop(25);
    //
    //         setIsLoaded(!isLoaded)
    //     }
    // }, [isLoading]);

    useEffect(() => {
        lottieRef.current.play();


    }, []);
    const handleHangarLoaded = () => {
        console.log("completed")
        if (!isLoading) {
            setIsLoaded(!isLoaded)
            lottieRef.current.stop();

        }
    }

    return (<div className='Loading'>
        <>

            <div className={`Loading-wrapper`}>
                <Loader lottieRef={lottieRef} callback={handleHangarLoaded}/>
                <div className={`Loading-gif-container`}>

                    {isLoaded ? (


                        <p className={'Loading-gif-btn ' + (isLoaded ? 'isAppeared' : '')}
                           onClick={onButtonPressed}>Visionnez nos vœux</p>) : (
                        <p className={'Loading-gif-text'}>Loading...</p>)}
                </div>
            </div>
        </>
    </div>)
}