import OverlayMobile from "./OverlayMobile";
import LoadingScreen from "./LoadingScreen";
import {useEffect, useState} from "react";

export default function MobileViewer({onHangarLoaded, model}) {

    const [buttonPressed, setButtonPressed] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [isAnimationComplete, setAnimationComplete] = useState(false);
    const video = document.getElementById('videoMobile')

    const handleButtonPressed = () => {
        setButtonPressed(true)
        setTimeout(() => {
            handleAnimationComplete(); // Change isLoading to false after the delay
        }, 1000);
    }
    useEffect(() => {
        // Simulating a delay of 1.5 seconds
        const delay = 1500;
        const timer = setTimeout(() => {
            setLoading(false); // Change isLoading to false after the delay
        }, delay);

        return () => clearTimeout(timer);

    }, []);
    const handleAnimationComplete = () => {
        // Callback function to be called when animation is complete
        setAnimationComplete(true);

        // setTimeout(() => {
        if (video) {
            video.play()
        }
        // }, 1000);
    };
    return <>
        <video className={'videoMobile'} id='videoMobile' src='./video/videoMobile_2.mp4'
               playsInline controls
               preload="metadata"
               webkit-playsinline='true' loop width='100%'
               height='100%'></video>
        {isAnimationComplete ? (
            <>
                <OverlayMobile/>
            </>

        ) : (
            <div className={`container ${buttonPressed ? 'inactive' : 'active'}`}>
                <LoadingScreen isLoading={isLoading} onButtonPressed={handleButtonPressed}/>
            </div>

        )}

    </>

}