import {Canvas} from '@react-three/fiber'
import {Effects} from './Effects'
import Camera from './Camera'
import Overlay from './Overlay'
import Hangar from './Hangar'
import LoadingScreen from './LoadingScreen'
import {useEffect, useState} from 'react'
import {useGLTF, Draco} from '@react-three/drei'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader'

export default function App() {
    const [isLoading, setIsLoading] = useState(true)
    const [buttonPressed, setButtonPressed] = useState(false)
    const [isAnimationComplete, setAnimationComplete] = useState(false);
    const [hangarModel, setHangarModel] = useState({})

    const handleButtonPressed = () => {
        setButtonPressed(true)
        setTimeout(() => {
            handleAnimationComplete(); // Change isLoading to false after the delay
        }, 1000);
    }
    const handleAnimationComplete = () => {
        // Callback function to be called when animation is complete
        setAnimationComplete(true);
    };
    const handleHangarLoaded = (gltf) => {
        setHangarModel(gltf)
        setIsLoading(false)
    }

    useEffect(() => {
        const draco = new DRACOLoader()
        draco.setDecoderPath('./draco/gltf/')

        const loader = new GLTFLoader()
        loader.setDRACOLoader(draco)
        const loadModel = async () => {
            try {
                const gltf = await new Promise((resolve, reject) => {
                    loader.load(
                        './hangar.glb',
                        resolve,
                        undefined,
                        reject
                    )
                })

                // Access nodes and materials based on the actual structure
                const nodes = gltf.scene ? gltf.scene.children : []
                const materials = nodes.map((node) => node.material)

                handleHangarLoaded(gltf)
            } catch (error) {
                console.error('Error loading hangar model:', error)
            }
        }

        // Call the loadModel function when the component mounts
        loadModel().then()
    }, [])

    return (
        <>
            {isAnimationComplete ? (
                <>
                    <Canvas
                        gl={{
                            antialias: false,
                            stencil: false,
                            powerPreference: 'high-performance',
                            depth: false,
                        }}
                        camera={{
                            fov: 50,
                            near: 0.01,
                            far: 200,
                            position: [0.0, 1.858, 15.0],
                            rotation: [0.0, 0.0, 0.0]
                        }}
                    >
                        <Hangar onHangarLoaded={handleHangarLoaded} model={hangarModel}/>
                        <Effects/>
                        <Camera/>
                    </Canvas>
                    <Overlay/>
                </>
            ) : (
                <div className={`container ${buttonPressed ? 'inactive' : 'active'}`}>
                    <LoadingScreen isLoading={isLoading} onButtonPressed={handleButtonPressed}/>
                </div>
            )}
        </>
    )
}
